import $ from "jquery";
import Masonry from "masonry-layout";
import "./mainMenu";
import "slick-carousel";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

let order = {};
const foodImages = {
  1: require("./images/data/dish_1_small.jpg"),
  5: require("./images/data/dish_5_small.jpg"),
  10: require("./images/data/dish_10_small.jpg"),
  11: require("./images/data/dish_11_small.jpg"),
  36: require("./images/data/dish_36_small.jpg"),
  62: require("./images/data/dish_62_small.jpg"),
  95: require("./images/data/dish_95_small.jpg"),
  98: require("./images/data/dish_98_small.jpg"),
  100: require("./images/data/dish_100_small.jpg"),
  103: require("./images/data/dish_103_small.jpg"),
  129: require("./images/data/dish_129_small.jpg"),
  173: require("./images/data/dish_173_small.jpg"),
  183: require("./images/data/dish_183_small.jpg"),
  201: require("./images/data/dish_201_small.jpg"),
};

const heroImages = [
  require("./images/hero/asian_aroma1.jpg"),
  require("./images/hero/asian_aroma2.jpg"),
  require("./images/hero/asian_aroma3.jpg"),
  require("./images/hero/asian_aroma4.jpg"),
  require("./images/hero/asian_aroma5.jpg"),
  require("./images/hero/asian_aroma6.jpg"),
  require("./images/hero/asian_aroma7.jpg"),
  require("./images/hero/asian_aroma8.jpg"),
  require("./images/hero/asian_aroma9.jpg"),
  require("./images/hero/asian_aroma10.jpg"),
  require("./images/hero/asian_aroma11.jpg"),
  require("./images/hero/asian_aroma12.jpg"),
  require("./images/hero/asian_aroma13.jpg"),
  require("./images/hero/asian_aroma14.jpg"),
  require("./images/data/soup.jpg"),
  require("./images/data/chicken-noodles.jpg"),
  require("./images/data/chicken-curry.jpg"),
];

document.querySelector(".hero--slider").innerHTML = heroImages
  .sort(() => 0.5 - Math.random())
  .slice(0, 3)
  .map(
    (item) =>
      `<div class="slide"><img alt="" src="${item}" loading="lazy" /></div>`
  )
  .join("");

$(".hero--slider").slick({
  infinite: true,
  arrows: false,
  dots: true,
  autoplay: true,
});

$('.menu--main a[href*="#"]').click(function () {
  if ($(this).attr("href") === "#order") {
    $(".order").slideToggle(200);
    $(this).toggleClass("u-active");
  } else {
    $(".order").slideUp(200);
    $(".menu--main a.u-active").removeClass("u-active");
    $(this).addClass("u-active");
  }
});

$("nav.menu--menu")
  .on("click", "a", function () {
    const name = this.dataset.menu;
    $(this).addClass("active").siblings().removeClass("active");

    $(`.menu--cat:not(#${name})`).addClass("hidden");
    $(`.menu--cat#${name}`).removeClass("hidden");

    if (name !== "PopularDishes") {
      const selector = `#${name} .menu--items.masonry`;
      const msnry = new Masonry(selector, {
        //columnWidth: 200,
        itemSelector: ".menu--group",
      });
    }

    return false;
  })
  .find("a:first-child")
  .trigger("click");

$(".shopping-cart").on("click", function () {
  $("#order").trigger("click");
});

$("body").on("click", ".quantity-selector button", function () {
  console.log(order);

  $(".shopping-cart").addClass("animate visible");
  console.log("asd");
  setTimeout(() => {
    $(".shopping-cart").removeClass("animate");
  }, 2000);

  const isOrderItem = $(this)
    .closest(".quantity-selector")
    .hasClass("order-item-selector");
  const $input = $(this).closest(".quantity-selector").find("input");
  let val = $input.val();

  if ($(this).hasClass("dec")) {
    val--;
  } else {
    val++;
  }

  if (val >= 0) {
    $input.val(val);
    $(this).closest("article").find(".quantity p span").text(val);
  }

  let title = "";
  let no = null;
  let price = null;
  if (isOrderItem) {
    title = $(this).closest(".order-item").find("h4").text();
    no = $(this).closest(".order-item").data("no");
    price = $(this).closest(".order-item").data("price");
  } else {
    title = $(this).closest("article").find("h4").text();
    no = $(this).closest("article").data("no");
    price = $(this).closest("article").data("price");
  }

  if (val >= 0) {
    order[no] = {
      qty: val,
      title: title,
      price: price,
    };
  }

  let txt = "";
  $(".order-items").empty();
  let total = 0;
  for (const [key, val] of Object.entries(order)) {
    if (!key || !val.title) {
      console.error("No title");
      continue;
    }

    const $item = $(".tpl-order-item")
      .clone()
      .removeClass("tpl-order-item")
      .data("no", key)
      .data("price", val.price);
    $item.find("h4").text(val.title);
    $item.find("input").val(val.qty);
    $item.find(".quantity span").text(val.qty);
    $(".order-items").append($item);
    total += val.price * val.qty;
    txt += `
      ${val.title} - ${val.qty}
    `;
    console.log(val);
  }
  const totalCurrency = total.toLocaleString("et-EE", {
    style: "currency",
    currency: "EUR",
  });
  $(".total-value").html(`${totalCurrency}`);
  $("#order-input").val(txt);

  return false;

  //console.log(order);
});

$("body").on("click", "article.menu--item:not(.no-takeaway)", function () {
  const $orderControls = $("body > .order-details").clone().addClass("visible");
  const no = $(this).data("no");
  if (foodImages[no]) {
    $orderControls
      .find("img")
      .attr("src", foodImages[no])
      .removeClass("hidden");
  }
  $(this).find(".order-details-container").html($orderControls);
  $(this).off("click");
});

const debouncedKeyUp = debounce(async function () {
  console.log($(this).val());
  const address = $(this).val();
  const url = `https://asianaroma-dev.netlify.app/.netlify/functions/geocode`;
  if (address.length < 8) return;
  const { data } = await axios.get(url, {
    params: {
      address,
    },
  });

  const distance_km = Math.round(data.distance_km * 10) / 10;
  $("p.delivery-info").css("opacity", 1);
  $("span.delivery-estimate").html(
    `<b>${data.courier_fee}</b> (~${distance_km}km)`
  );
  //console.log(data);
}, 250);

$("body").on("keyup", "input.address", debouncedKeyUp);
