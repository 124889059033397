/*
https://codepen.io/digistate/pen/OXXjXM
*/

import $ from 'jquery';
const $nav = $('#navigation');
const $slideLine = $('#slide-line');
const $currentItem = $('.current-item');

// Menu has active item
if ($currentItem.length) {
  $slideLine.css({
    'width': $currentItem.width(),
    'left': $currentItem.position().left
  });
}

// Underline transition
$nav.find('li').hover(
  // Hover on
  function(){
    $slideLine.css({
      'width': $(this).width(),
      'left': $(this).position().left
    });
  },
  // Hover out
  function(){
    const $currentItem = $('.current-item');
    if ($currentItem.length) {
      // Go back to current
      $slideLine.css({
        'width': $currentItem.width(),
        'left': $currentItem.position().left
      });
    } else {
      // Disapear
      $slideLine.width(0);
    }
  }
 )
.on('click', function() {
  $nav.find('li').removeClass('current-item')
  $(this).addClass('current-item')
});